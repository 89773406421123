/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import './filter.css';
import './createStaff.css';
import dowmArw from '../../images/down-arw.svg';
import closeIcon from '../../images/buttons-tp-x.svg';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { tenantUserDetailsSchema } from '../../utils/validation';
import { GlobalContext } from '../../contaxt/GlobalContext';
import Popup from '../../components/Popup/Popup';
import {
  jobTiitles,
  roleUpdateValues,
  roleValues,
  tenantUserRoles,
} from '../../utils/constants';
import Footer from '../../components/Footer/Footer';

import infoIcon from '../../images/buttons-info-icon.svg';
import MobileAccessPopup from '../../components/Popup/MobileAccessPopup';
import { useBuildingsMobileAccess } from '../../Selectors/buildingDataSelectors';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#444444',
    maxWidth: 190,
    width: '190 !important',
    color: '#fff',
    height: 'auto',
    border: 'none !important',
    fontSize: '11px',
    lineHeight: '18px',
    borderRadius: '10px',
    padding: '0 15px 15px 15px',
    fontFamily: 'Conv_AtlasGrotesk-Light-App',
    fontWeight: 'normal',
    boxShadow: 'none !important',
  },
  arrow: {
    color: '#444',
    fontSize: 20,
    // marginTop: 30,
    // position: 'relative',
    '&:before': {
      border: 'none !important',
    },
  },
}))(Tooltip);

const infoTitleData = (
  <div className="infoTitleData">
    <p>
      A super administrator has full access to all features of the Derwent
      London member portal.
    </p>
    <p>
      An employee has no access to the member portal but can use the DL/ App
    </p>
  </div>
);

const useStyles = makeStyles(() => ({
  CreateStaffForm: {
    boxShadow: '1px 3px 11px 1px rgba(0, 0, 0, 0.14)',
    background: '#fff',
    maxWidth: '550px',
    padding: '40px 44px',
    left: 0,
    right: 0,
    margin: '0 auto',
    '& h2': {
      fontSize: '15px',
      lineHeight: '20px',
      color: '#444444',
    },
  },
  formInputtBox: {
    width: '100%',
    '& > div': {
      width: '100%',
      marginTop: '0 !important',
      borderBottom: '1px solid #838384',
      paddingRight: '35px',
      height: '34px',
      fontSize: '13px',
      lineHeight: '20px',
      color: '#444444',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      '&:after': {
        display: 'none',
      },
      '&:before': {
        display: 'none',
      },
      '&  > div': {},
    },
  },
  addForm: {
    marginTop: '40px',
    '& ul': {
      '& li': {
        '& button': {
          fontSize: '13px',
          lineHeight: '47px',
          height: '47px',
          textAlign: 'center',
          color: '#fff',
          background: '#000',
          fontFamily: 'Conv_AtlasGrotesk-Medium-App',
          textTransform: 'inherit',
          width: '100%',
          borderRadius: '0',
          padding: '0',
          '&:hover': {
            background: '#000',
            boxShadow: 'none',
          },
        },
        marginBottom: '30px',
        '&:last-child': {
          marginBottom: '0',
        },
        '& label': {
          fontSize: '13px',
          lineHeight: '18px',
          color: '#444444',
          fontFamily: 'Conv_AtlasGrotesk-Regular-App',
          textTransform: 'capitalize',
        },
      },
    },
  },

  formControl: {
    width: '100%',
  },
  formSelectBox: {
    marginTop: '0 !important',
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      width: '100%',
      borderBottom: '1px solid #838384',
      fontSize: '13px',
      lineHeight: '20px',
      color: '#444444',
      fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      background: 'transparent !important',
      '&:after': {
        position: 'absolute',
        content: '""',
        background: `url(${dowmArw}) no-repeat 95% center !important`,
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        height: '10px',
        width: '10px',
      },
      '& div': {
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
  },
  CreateStaffBox: {
    padding: '8px 0',
  },
}));

const CreateTenantUser = () => {
  const {
    tenantId,
    buildingDataList,
    tenantData,
    setTenantUserSuccesssMsg,
    logOut,
    tenantUserRole,
    tenantDetailsData,
  } = useContext(GlobalContext);
  const buildingsMobileAccess = useBuildingsMobileAccess();
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [location, setLocation] = useState(
    buildingDataList.length > 1 ? '' : buildingDataList?.[0]?.id,
  );
  const [selectedLocation, setSelectedLocation] = useState({});
  const [jobTitle, setJobTitle] = useState('');
  const [email, setEmail] = useState('');
  const [domain, setDomain] = useState('');
  const [customDomain, setCustomDomain] = useState(domain);
  const [clearInput, setClearInput] = useState(false);
  const [role, setRole] = useState('');
  const [oldRole, setOldRole] = useState('');
  const [payToAccount, setPayToAccount] = useState(false);
  const [status, setStatus] = useState('');
  const [block, setBlock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPatyToAcc, setIsPatyToAcc] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState('');
  const [tenantDomains, setTenantDomains] = useState([]);
  const [isEmailExist, setIsEmailExist] = useState('');
  const [open, setOpen] = useState(false);
  const [createOrUpdateUserToggle, setCreateOrUpdateUserToggle] = useState(
    false,
  );
  const { name, village } = buildingDataList && buildingDataList[0];

  const [mobileAccessEnable, setMobileAccessEnable] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [tenantUserData, setTenantUserData] = useState('');

  const handleClick = buildingId => {
    const buildingDetails =
      buildingDataList &&
      buildingDataList.filter(data => data.id === buildingId);
    setSelectedLocation(buildingDetails[0]);
  };

  const mobileAccessManagement = () => {
    return mobileAccessEnable
      ? setOpenPopup(mobileAccessEnable)
      : setMobileAccessEnable(!mobileAccessEnable);
  };

  const breadCrumbs = id
    ? {
        backToPath: '/manage-staff-list',
        currentPage: 'Edit Your Staff',
        currentPagePath: `/edit-staff-user/${id}`,
      }
    : {
        backToPath: '/manage-staff-list',
        currentPage: 'Create Your Staff ',
        currentPagePath: '/create-staff-user',
      };

  const reqValidationCheck =
    firstName &&
    lastName &&
    location.length !== 0 &&
    jobTitle &&
    email &&
    (customDomain || domain) &&
    role;

  useEffect(() => {
    setLoading(true);
    ApiClient.apiGet(`${apiEndPoints.getTenants}/${tenantId}`)
      .then(data => {
        const tenantDetails = data && data.data && data.data.data;
        setIsPatyToAcc(tenantDetails && tenantDetails.payToAccount);
        setTenantDomains([
          ...(tenantDetails && tenantDetails.domains),
          ...['custom'],
        ]);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      ApiClient.apiGet(`${apiEndPoints.getTenantsUserId}${id}`)
        .then(data => {
          setLoading(false);
          const tetenantUserData = data && data.data && data.data.data;
          const accessData = tetenantUserData?.mobileAccess;
          const emailAndDomain = tetenantUserData?.email?.split('@');
          setFirstName(tetenantUserData?.firstName);
          setLastName(tetenantUserData?.lastName);
          setJobTitle(tetenantUserData?.jobTitle);
          setLocation(tetenantUserData?.building?.id);
          setSelectedLocation(tetenantUserData?.building);
          setRole(tetenantUserData?.role);
          setOldRole(tetenantUserData?.role);
          setEmail(emailAndDomain?.[0]);
          setDomain(emailAndDomain?.[1]);
          setStatus(tetenantUserData?.status);
          setBlock((tetenantUserData?.status === 'Blocked' && true) || false);
          setPayToAccount(tetenantUserData?.payToAccount);
          setMobileAccessEnable(accessData);
          setTenantUserData(tetenantUserData);
        })
        .catch(error => {
          if (error?.response?.status?.toString() === '440') logOut();
          console.log(error, 'error');
          setLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    tenantUserDetailsSchema
      .validate({
        firstName,
        lastName,
        location,
        jobTitle,
        email: `${email}${domain === 'custom' ? '' : '@'}${
          domain !== 'custom' ? domain : customDomain
        }`,
        role,
        payToAccount,
      })
      .then(() => {
        setErrorMsgs('');
        if (createOrUpdateUserToggle) {
          setLoading(true);
          const tetenantUserDataPay = id ? tenantUserData : tenantDetailsData;
          const payload = {
            ...tetenantUserDataPay,
            id: id || undefined,
            firstName,
            lastName,
            building:
              buildingDataList.length > 1
                ? selectedLocation
                : { id: location, name, village },
            tenant: tenantData,
            jobTitle,
            email: `${email}${domain === 'custom' ? '' : '@'}${
              domain !== 'custom' ? domain : customDomain
            }`,
            role,
            status: id ? status : undefined,
            payToAccount,
            mobileAccess: mobileAccessEnable,
          };
          if (id) {
            ApiClient.apiPut(
              `${apiEndPoints.createOrUpdateTenantUser}/${id}`,
              payload,
            )
              .then(() => {
                setLoading(false);
                setTenantUserSuccesssMsg(
                  'Your staff member has been updated successfully!',
                );
                history.push('/manage-staff-list');
              })
              .catch(error => {
                if (error.response.status.toString() === '440') logOut();
                setCreateOrUpdateUserToggle(false);
                setLoading(false);
                console.log('error', error);
              });
          } else {
            ApiClient.apiPost(apiEndPoints.createOrUpdateTenantUser, payload)
              .then(() => {
                setLoading(false);
                setTenantUserSuccesssMsg(
                  'Your staff member has been created successfully!',
                );
                history.push('/manage-staff-list');
              })
              .catch(error => {
                if (error.response.status.toString() === '440') logOut();
                console.log(error.message, 'error1');
                setCreateOrUpdateUserToggle(false);
                setLoading(false);
                setIsEmailExist(error.response.status);
              });
          }
        }
      })
      .catch(error => {
        console.log(error, 'error');
        if (createOrUpdateUserToggle) {
          setErrorMsgs(error);
          setCreateOrUpdateUserToggle(false);
        }
      });
  }, [
    firstName,
    lastName,
    location,
    jobTitle,
    email,
    role,
    domain,
    customDomain,
    payToAccount,
    createOrUpdateUserToggle,
  ]);

  // * If editing user role is employee then login user can't able to have edit this user from employee to Super Administrator(DCX-1266)
  const upatedRoles =
    tenantUserRole === tenantUserRoles.superAdministrator &&
    oldRole === tenantUserRoles.employee
      ? roleUpdateValues?.filter(
          item => item !== tenantUserRoles.superAdministrator,
        )
      : roleUpdateValues;

  const errorType = errorMsgs && errorMsgs.type;
  const errorFieldName = errorMsgs && errorMsgs.path;
  const errorFieldValue = errorMsgs && errorMsgs.value;

  const emailValidation =
    isEmailExist.toString() === '409'
      ? 'Email already exists'
      : email.length > 0 && domain.length > 0
      ? ''
      : 'Email address is required.';

  return (
    <>
      <Header />
      <div className={`${classes.CreateStaff} pagecontainer`}>
        <div className="CreateStafBanner">
          <Breadcrumb breadCrumbs={breadCrumbs} colorChange />
          {loading ? (
            <CircularProgress color="inherit" />
          ) : (
            <div className={classes.CreateStaffBox}>
              <div className={classes.CreateStaffForm}>
                <>
                  {id ? (
                    <h2>Edit your staff member</h2>
                  ) : (
                    <h2>
                      Create your staff here so that they can have permission to
                      access your company’s information and data. Update copy
                      for app.{' '}
                    </h2>
                  )}
                  <form className={`${classes.addForm} addForm`}>
                    <ul>
                      <li>
                        <TextField
                          className={classes.formInputtBox}
                          type="text"
                          id="standard-basic"
                          label="First Name*"
                          value={firstName}
                          onChange={e => {
                            const re = /^[A-Za-z0-9 ]+$/;
                            if (
                              e.target.value === '' ||
                              re.test(e.target.value)
                            ) {
                              setFirstName(e.target.value);
                            }
                          }}
                        />
                        {((errorType === 'required' &&
                          errorFieldName === 'firstName') ||
                          (errorFieldValue &&
                            errorFieldValue.firstName === '')) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            component="div"
                            error={
                              firstName === '' && 'First name is required.'
                            }
                          >
                            {firstName === '' && 'First name is required.'}
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        <TextField
                          className={classes.formInputtBox}
                          type="text"
                          id="standard-basic"
                          label="Last Name*"
                          value={lastName}
                          onChange={e => {
                            const re = /^[A-Za-z0-9 ]+$/;
                            if (
                              e.target.value === '' ||
                              re.test(e.target.value)
                            ) {
                              setLastName(e.target.value);
                            }
                          }}
                        />
                        {((errorType === 'required' &&
                          errorFieldName === 'lastName') ||
                          (errorFieldValue &&
                            errorFieldValue.lastName === '')) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            component="div"
                            error={lastName === '' && 'Last name is required.'}
                          >
                            {lastName === '' && 'Last name is required.'}
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Location*
                          </InputLabel>
                          <Select
                            className={classes.formSelectBox}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                            value={location.toString()}
                            onChange={e => {
                              setLocation(e.target.value);
                              handleClick(e.target.value);
                            }}
                          >
                            {buildingDataList &&
                              buildingDataList.map(item => (
                                <MenuItem
                                  insetchildren="true"
                                  checked={item && item.name}
                                  key={item.id}
                                  value={item.id}
                                  className="multiSelectMenu"
                                  primarytext={item.name}
                                >
                                  {`${item.name} (${item.village})`}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        {((errorType === 'required' &&
                          errorFieldName === 'location') ||
                          (errorFieldValue &&
                            errorFieldValue.location === '')) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            component="div"
                            error={location === '' && 'Location is required.'}
                          >
                            {location === '' && 'Location is required.'}
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Job Role*
                          </InputLabel>
                          <Select
                            className={classes.formSelectBox}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                            value={jobTitle}
                            onChange={e => setJobTitle(e.target.value)}
                          >
                            {jobTiitles &&
                              jobTiitles.map(jobType => (
                                <MenuItem key={jobType} value={jobType}>
                                  {jobType}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        {((errorType === 'required' &&
                          errorFieldName === 'jobTitle') ||
                          (errorFieldValue &&
                            errorFieldValue.jobTitle === '')) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            component="div"
                            error={jobTitle === '' && 'Job role is required.'}
                          >
                            {jobTitle === '' && 'Job role is required.'}
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        <TextField
                          className={classes.formInputtBox}
                          type="text"
                          id="standard-basic"
                          label="Email*"
                          disabled={id}
                          value={email}
                          onChange={e => {
                            const re = /^[A-Za-z0-9.]+$/;
                            if (
                              e.target.value === '' ||
                              re.test(e.target.value)
                            ) {
                              setIsEmailExist('');
                              setEmail(e.target.value);
                            }
                          }}
                        />
                        {((errorType === 'email' &&
                          errorFieldName === 'email') ||
                          (errorFieldValue && errorFieldValue.email === '@') ||
                          isEmailExist.toString() === '409') && (
                          <FormHelperText
                            className={classes.errorMsg}
                            component="div"
                            error={
                              email === '' ||
                              domain === '' ||
                              errorFieldName === 'email'
                                ? 'Please enter a valid email.'
                                : email + (domain || customDomain) === '' ||
                                  email === '' ||
                                  domain === '' ||
                                  emailValidation
                            }
                          >
                            {email === '' ||
                            domain === '' ||
                            errorFieldName === 'email'
                              ? 'Please enter a valid email.'
                              : email + (domain || customDomain) === '' ||
                                email === '' ||
                                domain === '' ||
                                emailValidation}
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        <FormControl className={classes.formControl}>
                          {!id ? (
                            <>
                              {domain === 'custom' && clearInput === false ? (
                                <TextField
                                  className={classes.formInputtBox}
                                  type="text"
                                  id="standard"
                                  label="Domain*"
                                  autoFocus
                                  value={`${customDomain}`}
                                  onChange={e => {
                                    const re = /^[A-Za-z0-9.@]+$/;
                                    if (
                                      e.target.value === '' ||
                                      re.test(e.target.value)
                                    ) {
                                      setIsEmailExist('');
                                      setCustomDomain(e.target.value);
                                      setClearInput(false);
                                    }
                                  }}
                                />
                              ) : (
                                <>
                                  <InputLabel id="demo-simple-select-label">
                                    Domain*
                                  </InputLabel>
                                  <Select
                                    className={classes.formSelectBox}
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      },
                                      getContentAnchorEl: null,
                                    }}
                                    disabled={id}
                                    value={domain === 'custom' ? '' : domain}
                                    onChange={e => {
                                      setDomain(e.target.value);
                                      setClearInput(false);
                                    }}
                                  >
                                    {tenantDomains &&
                                      tenantDomains.map(tenantDomain => (
                                        <MenuItem
                                          key={tenantDomain}
                                          value={tenantDomain}
                                        >
                                          {`${
                                            tenantDomain !== 'custom' ? '@' : ''
                                          }${tenantDomain}`}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </>
                              )}
                            </>
                          ) : (
                            <TextField
                              className={classes.formInputtBox}
                              type="text"
                              id="standard-basic"
                              label="Domain*"
                              disabled={id}
                              value={
                                domain.includes('@') ? domain : `@${domain}`
                              }
                              onChange={e => setDomain(e.target.value)}
                            />
                          )}
                        </FormControl>
                        {domain === 'custom' && clearInput === false && (
                          <span className="popupClose">
                            <img
                              src={closeIcon}
                              alt="closeIcon"
                              role="presentation"
                              onClick={() => {
                                setCustomDomain('');
                                setClearInput(!clearInput);
                              }}
                            />
                          </span>
                        )}
                      </li>
                      <li>
                        <FormControl className={classes.formControl}>
                          <InputLabel
                            id="demo-simple-select-label"
                            className="infoIconLabel"
                          >
                            <div className="tooltipInfo">
                              <span>Portal role*</span>
                              <HtmlTooltip
                                title={infoTitleData}
                                arrow
                                placement="right-start"
                                classes={{ tooltip: 'custom-width' }}
                                PopperProps={{
                                  popperOptions: {
                                    modifiers: {
                                      offset: {
                                        enabled: true,
                                        offset: '0px 0px',
                                      },
                                    },
                                  },
                                }}
                              >
                                <span className="tooltipInfoIcon">
                                  {' '}
                                  <img src={infoIcon} alt={infoIcon} />{' '}
                                </span>
                              </HtmlTooltip>{' '}
                            </div>
                          </InputLabel>
                          <Select
                            className={classes.formSelectBox}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                            value={role}
                            onChange={e => setRole(e.target.value)}
                          >
                            {id
                              ? upatedRoles?.map(roleType => (
                                  <MenuItem key={roleType} value={roleType}>
                                    {roleType}
                                  </MenuItem>
                                ))
                              : roleValues &&
                                roleValues.map(roleType => (
                                  <MenuItem key={roleType} value={roleType}>
                                    {roleType}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                        {((errorType === 'required' &&
                          errorFieldName === 'role') ||
                          (errorFieldValue && errorFieldValue.role === '')) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            component="div"
                            error={role === '' && 'Portal role is required.'}
                          >
                            {role === '' && 'Portal role is required.'}
                          </FormHelperText>
                        )}
                      </li>
                      <li className="ptaBox">
                        <div className="ptaLabel" disabled>
                          Pay To Account Access
                        </div>
                        <div className="ptaSwitch">
                          <div className="toggle-button-cover">
                            <div className="button-cover">
                              <div className="button b2 buttonSwitch">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  checked={!payToAccount}
                                  disabled={!isPatyToAcc}
                                  onClick={() => setPayToAccount(!payToAccount)}
                                />
                                <div className="knobs">
                                  <span>&nbsp;</span>
                                </div>
                                <div className="layer">&nbsp;</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {buildingsMobileAccess ? (
                        <li className="ptaBox">
                          <div className="ptaLabel">Mobile Access Enabled</div>
                          <div className="ptaSwitch">
                            <div className="toggle-button-cover">
                              <div className="button-cover">
                                <div className="button b2 buttonSwitch">
                                  <input
                                    disabled={block}
                                    type="checkbox"
                                    className="checkbox"
                                    checked={!mobileAccessEnable}
                                    onClick={mobileAccessManagement}
                                  />
                                  <div className="knobs">
                                    <span>&nbsp;</span>
                                  </div>
                                  <div className="layer">&nbsp;</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ) : (
                        ''
                      )}
                      {id && (
                        <li className="ptaBox">
                          <div className="ptaLabel">Block</div>
                          <div className="ptaSwitch">
                            <div className="toggle-button-cover">
                              <div className="button-cover">
                                <div className="button b2 buttonSwitch">
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={!block}
                                    onClick={() => {
                                      setBlock(!block);
                                      setStatus(!block ? 'Blocked' : 'Active');
                                      setOpen(!block && true);
                                    }}
                                  />
                                  <div className="knobs">
                                    <span>&nbsp;</span>
                                  </div>
                                  <div className="layer">&nbsp;</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                      {id ? (
                        <li className="editButtons">
                          <Button
                            onClick={() => history.push('/manage-staff-list')}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={!reqValidationCheck}
                            onClick={() => setCreateOrUpdateUserToggle(true)}
                          >
                            Save
                          </Button>
                        </li>
                      ) : (
                        <li>
                          <Button
                            disabled={!reqValidationCheck}
                            onClick={() => setCreateOrUpdateUserToggle(true)}
                          >
                            Create user
                          </Button>
                        </li>
                      )}
                    </ul>
                    <Popup
                      open={open}
                      setOpen={setOpen}
                      block={block}
                      setBlock={setBlock}
                      setStatus={setStatus}
                    />
                    <MobileAccessPopup
                      openPopup={openPopup}
                      setOpenPopup={setOpenPopup}
                      setMobileAccessEnable={setMobileAccessEnable}
                      mobileAccessEnable={mobileAccessEnable}
                    />
                  </form>
                </>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default CreateTenantUser;
