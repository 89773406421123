import { GET_TENANT_DETAILS ,GET_TENANT_USER_STATUS} from '../utils/constants';

const getTenantUserDetails = action => ({
  type: GET_TENANT_DETAILS,
  action,
});

export const getTenantDetails = tenantDetails => dispatch => {
  dispatch(getTenantUserDetails(tenantDetails));
};

const getTenantUserStatusKeys = action => ({
  type: GET_TENANT_USER_STATUS,
  action,
});

export const getTenantUserStatus = tenantUserStatus => dispatch => {
  dispatch(getTenantUserStatusKeys(tenantUserStatus));
};