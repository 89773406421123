import { useSelector } from 'react-redux';

export const useBuildingsMobileAccess = () => {
  return useSelector(state => {
    const userSwiftConnectAccess =
      state?.tenantUserStatusReducer?.tenantUserStatusReducer;
    if (
      userSwiftConnectAccess?.buildingMobileAccess &&
      userSwiftConnectAccess?.tenantBuildingMobileAccess 
    )
      return true;
    return false;
  });
};
