/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
/* eslint-disable react/destructuring-assignment */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ManageStaff from '../container/ManageStaff/ManageStaff';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';
import { GlobalContext } from './GlobalContext';
import { getBuildingsData } from '../Actions/getBuildingsActions';

export const ManageStaffContext = createContext({});

const ManageStaffContextProvider = () => {
  const dispatch = useDispatch();
  const {
    tenantId,
    buildingDataList,
    setBuildingDataList,
    logOut,
  } = useContext(GlobalContext);
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('firstName');
  const [sortOrder, setSortOrder] = useState('asc');
  const [totalTenants, setTotalTenants] = useState(0);
  const [page, setPage] = useState(0);
  const [tenantActiveUsers, setTenantActiveUsers] = useState('');
  const [tenantsUsersList, setTenantsUsersList] = useState([]);
  const [location, setLocation] = useState([]);
  // const [buildingDataList, setBuildingDataList] = useState([]);
  const [payToAccount, setPayToAccount] = useState([]);
  const [role, setRole] = useState([]);
  const [status, setStatus] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [mobileAccessEnable, setMobileAccessEnable] = useState([]);

  const getBuildingCallAction = buildingsPayload => {
    // get buildings api call.
    ApiClient.apiPost(apiEndPoints.getBuildings, buildingsPayload)
      .then(builldingsData => {
        dispatch(
          getBuildingsData(
            builldingsData && builldingsData.data && builldingsData.data.data,
          ),
        );
        setBuildingDataList(
          builldingsData && builldingsData.data && builldingsData.data.data,
        );
        // setLoading(false);
      })
      .catch(error => {
        if (error.response.status.toString() === '440') logOut();
        console.log(error, 'error');
      });
  };

  const filterdBuilding = [];
  const featureBuildingName = [];

  location.map(selectedFeature => {
    buildingDataList.map(featureId => {
      if (selectedFeature === featureId.id) {
        const existsFeature = featureBuildingName.find(
          id => id.id === selectedFeature,
        );
        if (existsFeature) {
          filterdBuilding.push(
            featureBuildingName.filter(
              object => location.indexOf(object.id) > -1,
            ),
          );
        } else {
          featureBuildingName.push({
            id: featureId.id,
            name: featureId.name,
            village: featureId.village,
          });
        }
      }
    });
  });

  // get Tenants UserList API Call
  const getTenantusersListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getTenantsUsersList, payload)
      .then(data => {
        setTenantActiveUsers(data && data.data.activeUsers);
        setTotalTenants(data && data.data.count);
        setTenantsUsersList(data && data.data && data.data.data);
        setLoading(false);
      })
      .catch(error => {
        if (error.response.status.toString() === '440') logOut();
        console.log(error, 'error');
        setLoading(false);
      });
  };

  useEffect(() => {
    if (tenantId) {
      getBuildingCallAction({
        tenantId,
        sortBy: 'name',
        sortOrder: 'asc',
        offset: 0,
        limit: 500,
      });
    }
  }, [tenantId]);

  // combination for Filters API for Tenant Users passing payload as parameter
  useEffect(() => {
    if (tenantId) {
      getTenantusersListAction({
        tenantId: tenantId || undefined,
        searchText: searchText.length > 2 ? searchText : undefined,
        sortOrder,
        sortBy,
        buildings: (location && location.length > 0 && location) || undefined,
        payToAccount:
          payToAccount && payToAccount.length > 0 ? payToAccount : undefined,
        role: role && role.length > 0 ? role : undefined,
        mobileAccess:
          mobileAccessEnable && mobileAccessEnable.length > 0
            ? mobileAccessEnable
            : undefined,
        status: status && status.length > 0 ? status : ['Active'],
        offset,
        limit: 40,
      });
    }
  }, [
    page,
    offset,
    sortBy,
    sortOrder,
    location,
    payToAccount,
    searchText,
    role,
    status,
    tenantId,
    mobileAccessEnable,
  ]);

  return (
    <ManageStaffContext.Provider
      value={{
        page,
        setPage,
        offset,
        setOffset,
        sortBy,
        setSortBy,
        sortOrder,
        setSortOrder,
        totalTenants,
        tenantActiveUsers,
        loading,
        tenantsUsersList,
        setTenantsUsersList,
        payToAccount,
        setPayToAccount,
        role,
        setRole,
        mobileAccessEnable,
        setMobileAccessEnable,
        status,
        setStatus,
        buildingDataList,
        searchText,
        setSearchText,
        setBuildingDataList,
        location,
        setLocation,
      }}
    >
      <ManageStaff />
    </ManageStaffContext.Provider>
  );
};

export default ManageStaffContextProvider;
